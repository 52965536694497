import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormRow } from '@homepl/onestore-ui-components/dist/Form';
import { ErrorText } from '@homepl/onestore-ui-components/dist/ErrorText';
import { LabelBar, LabelBarText } from '@homepl/onestore-ui-components/dist/LabelBar';
import { CheckoutTextWithSub, CheckoutTextWithSubTitle, CheckoutTextWithSubSubtext } from '@homepl/onestore-ui-components/dist/CheckoutTextWithSub';
import LoginFormFooter from '../LoginFormFooter';
import FormInput from '../FormInput';

export default function LoginForm({
  title,
  titleSubtext,
  hideRecovery,
  login,
  onValueChange,
  loading,
  error,
  errorMessage,
  password,
  onSubmit,
  submitText,
  loginPlaceholder,
  passwordPlaceholder,
  loginSecondaryPlaceholder,
  passwordSecondaryPlaceholder,
  validatePassword,
  infoText,
}) {
  return (
    <React.Fragment>
      <LabelBar>
        <LabelBarText>
          <CheckoutTextWithSub>
            <CheckoutTextWithSubTitle>{title}</CheckoutTextWithSubTitle>
            <CheckoutTextWithSubSubtext>{titleSubtext}</CheckoutTextWithSubSubtext>
          </CheckoutTextWithSub>
        </LabelBarText>
      </LabelBar>
      <Form>
        <form onSubmit={onSubmit}>
          <FormRow>
            <FormInput
              value={login}
              name="login"
              onValueChange={onValueChange}
              label={login.length > 0 ? loginSecondaryPlaceholder : loginPlaceholder}
              loading={loading}
              error={error}
            />
          </FormRow>
          <FormRow>
            <FormInput
              value={password}
              name="password"
              onValueChange={onValueChange}
              label={password.length > 0 ? passwordSecondaryPlaceholder : passwordPlaceholder}
              loading={loading}
              error={error}
              type="password"
              validatePassword={validatePassword}
            />
          </FormRow>
          <FormRow>
            {error
              && <ErrorText>{errorMessage}</ErrorText>
            }
          </FormRow>
          <LoginFormFooter
            hideRecovery={hideRecovery}
            onClick={onSubmit}
            disabled={login.length === 0 || password.length === 0}
            loading={loading}
            submitText={submitText}
            infoText={infoText}
          />
        </form>
      </Form>
    </React.Fragment>
  );
}

LoginForm.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  login: PropTypes.string,
  password: PropTypes.string,
  submitText: PropTypes.string,
  title: PropTypes.string,
  titleSubtext: PropTypes.string,
  hideRecovery: PropTypes.bool,
  loginPlaceholder: PropTypes.string,
  passwordPlaceholder: PropTypes.string,
  loginSecondaryPlaceholder: PropTypes.string,
  passwordSecondaryPlaceholder: PropTypes.string,
  infoText: PropTypes.string,
  validatePassword: PropTypes.bool,
};

LoginForm.defaultProps = {
  error: false,
  errorMessage: 'Login i/lub hasło są nieprawidłowe. Pamiętaj, że Twoim loginem jest adres e-mail (nowy panel) lub nazwa usługi (stary panel).',
  loading: false,
  password: '',
  login: '',
  infoText: null,
  submitText: 'Zaloguj się',
  title: '',
  titleSubtext: '',
  hideRecovery: false,
  loginPlaceholder: 'Wpisz swój login',
  passwordPlaceholder: 'Hasło',
  loginSecondaryPlaceholder: 'Login',
  passwordSecondaryPlaceholder: 'Twoje hasło',
  validatePassword: true,
};
