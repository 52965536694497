import "whatwg-fetch";
import redirect from "./redirect";

function fetchPost(path, data) {
  return fetch(`${window.appConfig.apiHost}${path}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  });
}

async function handleForcedRedirect(response) {
  if (response.status === 303) {
    redirect(response);
  }

  return response;
}
async function normalizeResponse(response) {
  let json = null;

  try {
    json = await response.json();
  } catch (error) {
    // ignore
  }

  return {
    status: response.status,
    json,
  };
}

export default class Api {
  static authenticate(login, password, captcha) {
    return fetchPost("/authentication", { login, password, captcha })
      .then(normalizeResponse)
      .then(handleForcedRedirect);
  }

  static updateUser(login, password, token, code = null) {
    return fetchPost(
      "/users",
      {
        login,
        password,
        token,
        code,
      },
    ).then(normalizeResponse)
      .then(handleForcedRedirect);
  }

  static verifyUser(login, password, token) {
    return fetchPost("/verify", { login, password, token })
      .then(normalizeResponse)
      .then(handleForcedRedirect);
  }

  static recover(login, captcha) {
    return fetchPost("/recovery", { login, captcha })
      .then(normalizeResponse)
      .then(handleForcedRedirect);
  }
}
