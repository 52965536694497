import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Header from "../components/PageHeader";
import Api from "../lib/Api";
import PasswordRecoveryForm from "../components/PasswordRecoveryForm";
import PasswordRecoveryDone from "../components/PasswordRecoveryDone";
import redirect from "../lib/redirect";
import Storage from "../lib/Storage";
import InformationBox from "../components/InformationBox";

export default function Recovery({ setSidebarVisibility }) {
  const [state, setState] = useState({
    value: "",
    loading: false,
    validationError: false,
    login: null,
    oldLogin: null,
    migrationDate: null,
    errorMessage: null,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    setSidebarVisibility(true);
  }, []);

  const onValueChange = (event) => {
    setState({
      ...state,
      validationError: false,
      value: event.target.value,
      resultStatus: undefined,
    });
  };

  const onResendPassword = () => {
    setState({
      ...state,
      value: "",
      loading: false,
      resultStatus: undefined,
    });
  };

  const recoverPassword = async (event) => {
    event.preventDefault();

    if (state.value.length === 0) {
      setState({ ...state, validationError: true });

      return;
    }

    setState({
      ...state,
      loading: true,
      resultStatus: undefined,
      validationError: false,
    });

    try {
      const captcha = await executeRecaptcha("Recover_password");
      const result = await Api.recover(state.value, captcha);
      const { status, json } = result;

      if (status === 200) {
        if (json.migration_date) {
          setState({
            ...state,
            value: "",
            login: json.login,
            oldLogin: json.old_login,
            migrationDate: json.migration_date,
            loading: false,
            resultStatus: status,
          });
        } else {
          redirect(result);
        }
      } else if (result.status === 204) {
        Storage.setLogin(state.value);
        setState({
          ...state,
          migrationDate: null,
          resultStatus: result.status,
          loading: false,
          validationError: false,
        });
      } else if (result.status >= 400) {
        setState({
          ...state,
          migrationDate: null,
          login: null,
          oldLogin: null,
          loading: false,
          resultStatus: result.status,
          validationError: true,
          errorMessage: Array.isArray(json.errors)
            ? json.errors.join(", ")
            : undefined,
        });
      }
    } catch (error) {
      setState({
        ...state,
        loading: false,
        resultStatus: 500,
        validationError: true,
        migrationDate: null,
        login: null,
        oldLogin: null,
      });
    }
  };

  const {
    migrationDate,
    login,
    oldLogin,
    loading,
    resultStatus,
    validationError,
    value,
    errorMessage,
  } = state;
  let submitText = !loading ? "Przejdź dalej" : "Przetwarzanie...";

  if (resultStatus >= 400) {
    submitText = "Spróbuj ponownie";
  }
  return (
    <React.Fragment>
      <Header>Odzyskaj dostęp</Header>

      {migrationDate && (
        <InformationBox
          secondary
          title="Informacja"
          login={login}
          oldLogin={oldLogin}
          text="Twoje usługi są w nowym panelu, a Twoim loginem jest adres e-mail."
        />
      )}

      {resultStatus === 204 ? (
        <PasswordRecoveryDone onClick={onResendPassword} />
      ) : (
        <PasswordRecoveryForm
          onSubmit={recoverPassword}
          onValueChange={onValueChange}
          loading={loading}
          error={validationError}
          errorMessage={errorMessage}
          value={value}
          loginSubtext={
            migrationDate
              ? "Adres e-mail"
              : "Adres e-mail lub identyfikator istniejącej usługi"
          }
          linkToLogin={migrationDate && true}
          submitText={submitText}
        />
      )}
    </React.Fragment>
  );
}

Recovery.propTypes = {
  setSidebarVisibility: PropTypes.func,
};

Recovery.defaultProps = {
  setSidebarVisibility: null,
};
