import Api from "../lib/Api";
import Storage from "../lib/Storage";

export const SET_NEW_LOGIN = "@app/SET_NEW_LOGIN";
export const SET_NEW_PASSWORD = "@app/SET_NEW_PASSWORD";
export const VALIDATE_NEW_CREDENTIALS = "@app/VALIDATE_NEW_CREDENTIALS";
export const SETUP_NEW_CREDENTIALS = "@app/SETUP_NEW_CREDENTIALS";
export const SETUP_NEW_CREDENTIALS_SUCCESS = "@app/SETUP_NEW_CREDENTIALS_SUCCESS";
export const SETUP_NEW_CREDENTIALS_FAILURE = "@app/SETUP_NEW_CREDENTIALS_FAILURE";
export const RESET_NEW_CREDENTIALS = "@app/RESET_NEW_CREDENTIALS";
export const SET_NEW_CREDENTIALS_ERRORS = "@app/SET_NEW_CREDENTIALS_ERRORS";

export const initialState = {
  login: "",
  password: "",
  loading: false,
  errors: [],
  attempts: 0,
  success: false
};

// reducers
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NEW_LOGIN:
      return {
        ...state,
        login: action.payload.login,
        errors: []
      };
    case SET_NEW_PASSWORD:
      return {
        ...state,
        password: action.payload.password,
        errors: []
      };
    case VALIDATE_NEW_CREDENTIALS:
      return {
        ...state,
        errors: action.payload.errors,
        loading: false
      };
    case SETUP_NEW_CREDENTIALS:
      return {
        ...state,
        loading: true
      };
    case SETUP_NEW_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        password: null,
        attempts: action.payload.attempts,
        success: true
      };
    case SETUP_NEW_CREDENTIALS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors
      };
    case RESET_NEW_CREDENTIALS:
      return {
        ...state,
        password: "",
        loading: false,
        success: false,
        errors: action.payload.errors || [],
        attemots: 0
      };
    case SET_NEW_CREDENTIALS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors
      };
    default:
      return state;
  }
}

// selectors
export function getNewCredentials(state) {
  const { login, password } = state.setupNewCredentials;

  return {
    login,
    password
  };
}

export function isLoading(state) {
  return state.setupNewCredentials.loading;
}

export function isSuccess(state) {
  return state.setupNewCredentials.success;
}

export function hasErrors(state) {
  return state.setupNewCredentials.errors.length > 0;
}

export function getErrors(state) {
  return state.setupNewCredentials.errors;
}

export function getAttempts(state) {
  return state.setupNewCredentials.attempts;
}

// actions
export function setNewLogin(login) {
  return {
    type: SET_NEW_LOGIN,
    payload: {
      login
    }
  };
}

export function setNewPassword(password) {
  return {
    type: SET_NEW_PASSWORD,
    payload: {
      password
    }
  };
}

export function validateNewCredentials() {
  return (dispatch, getState) => {
    const { login, password } = getNewCredentials(getState());
    const errors = [];
    if (!login || login.length === 0 || login.indexOf("@") === -1 || login.indexOf(".") === -1) {
      errors.push("Wprowadź poprawny adrese e-mail");
    }

    if (password.length === 0) {
      errors.push("Proszę wprowadzić hasło");
    }

    dispatch({
      type: VALIDATE_NEW_CREDENTIALS,
      payload: {
        errors
      }
    });
  };
}

export function setupNewCredentials() {
  return async (dispatch, getState) => {
    const state = getState();

    if (isLoading(state) || hasErrors(state)) {
      return;
    }

    dispatch({
      type: SETUP_NEW_CREDENTIALS
    });

    const { login, password } = getNewCredentials(state);
    try {
      const result = await Api.verifyUser(login, password, Storage.getToken());
      const { status, json } = result;

      if ((status >= 200 && status < 400) || status === 429) {
        Storage.setEmail(login);
        Storage.setPassword(password);

        dispatch({
          type: SETUP_NEW_CREDENTIALS_SUCCESS,
          payload: {
            attempts: json.attempt
          }
        });
      } else {
        dispatch({
          type: SETUP_NEW_CREDENTIALS_FAILURE,
          payload: {
            errors: json.errors || []
          }
        });
      }
    } catch (error) {
      // TODO: Dodać logowanie błędów do Sentry
      dispatch({
        type: SETUP_NEW_CREDENTIALS_FAILURE,
        payload: {
          errors: ["Wystapił błąd. Spróbuj ponownie później."]
        }
      });
    }
  };
}

export function resetNewCredentials(errors = []) {
  return {
    type: RESET_NEW_CREDENTIALS,
    payload: {
      errors
    }
  };
}

export function setNewCredentialsErrors(errors) {
  return {
    type: SET_NEW_CREDENTIALS_ERRORS,
    payload: {
      errors
    }
  };
}
