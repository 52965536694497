import React from "react";
import PropTypes from "prop-types";
import { Form, FormRow } from "@homepl/onestore-ui-components/dist/Form";
import { LabelBar, LabelBarText } from "@homepl/onestore-ui-components/dist/LabelBar";
import { Cta, CtaBtn } from "@homepl/onestore-ui-components/dist/Cta";
import {
  CheckoutTextWithSub,
  CheckoutTextWithSubTitle,
  CheckoutTextWithSubSubtext,
} from "@homepl/onestore-ui-components/dist/CheckoutTextWithSub";
import { Link as RouterLink } from "react-router-dom";

export default function PasswordRecoveryDone({ onClick }) {
  return (
    <React.Fragment>
      <LabelBar>
        <LabelBarText>
          <CheckoutTextWithSub>
            <CheckoutTextWithSubTitle>
              Jeśli podałeś/aś poprawny adres lub identyfikator usługi, wysłaliśmy link do resetu
              hasła na przypisany do niego e-mail.
            </CheckoutTextWithSubTitle>
          </CheckoutTextWithSub>
        </LabelBarText>
      </LabelBar>
      <Form>
        <FormRow>
          <Cta>
            <CtaBtn>
              <RouterLink to="/" className="a-btn">
                Wróć do logowania
              </RouterLink>
            </CtaBtn>
          </Cta>
        </FormRow>
        <FormRow>
          <LabelBar>
            <LabelBarText>
              <CheckoutTextWithSub>
                <CheckoutTextWithSubSubtext>
                  Wiadomość nie dotarła? Sprawdź folder SPAM, zweryfikuj ewentualne literówki w
                  adresie e-mail i/lub{" "}
                  <RouterLink to="/reset-hasla" onClick={onClick} className="a-link--underline">
                    wyślij link z resetem hasła ponownie
                  </RouterLink>.
                  <br/>
                  <br/>
                  W razie czego{" "}
                  <a
                    href="https://pomoc.home.pl/baza-wiedzy/jak-zmienic-haslo-w-home-pl"
                    target="_blank"
                    className="a-link--underline"
                  >
                    tutaj dowiesz się więcej o zmianie hasła
                  </a>
                  .
                </CheckoutTextWithSubSubtext>
              </CheckoutTextWithSub>
            </LabelBarText>
          </LabelBar>
        </FormRow>
      </Form>
    </React.Fragment>
  );
}

PasswordRecoveryDone.propTypes = {
  onClick: PropTypes.func.isRequired,
};
