import React from 'react';
import { Form, FormRow } from '@homepl/onestore-ui-components/dist/Form';
import { Cta, CtaBtn } from '@homepl/onestore-ui-components/dist/Cta';
import PropTypes from 'prop-types';
import Header from '../components/PageHeader';
import InformationBox from '../components/InformationBox';
import VersionText from '../components/VersionText';

export default function MigrationInProgress({ setSidebarVisibility }) {
  React.useEffect(() => {
    setSidebarVisibility(false);
  });

  return (
    <React.Fragment>
      <Header>Twoje usługi cały czas działają poprawnie</Header>
      <InformationBox
        secondary
        title="Twoje usługi znajdą się w nowym panelu"
        text="Wkrótce przeniesiemy Twoje usługi do nowego, ulepszonego panelu home.pl. Stanie się to za:"
      >
        <h2 className="a-text--center a-text--secondary-tint">
          około 2 godziny
        </h2>
        <p className="a-text a-text--secondary a-text--center">
          Potrzebujesz zmienić coś na swojej stronie WWW?
          <br />
          {' '}
Zrób to, używając FTP lub SSH.
        </p>
      </InformationBox>
      <Form>
        <FormRow>
          <Cta>
            <CtaBtn>
              <a href="https://home.pl" className="a-btn">Wróć na home.pl</a>
            </CtaBtn>
          </Cta>
        </FormRow>
      </Form>
      <VersionText>vn: F0x100</VersionText>
    </React.Fragment>
  );
}

MigrationInProgress.propTypes = {
  setSidebarVisibility: PropTypes.func,
};
