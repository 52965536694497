import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import Header from "../components/PageHeader";
import ConfirmationCodeForm from "../components/ConfirmationCodeForm";
import Storage from "../lib/Storage";
import Api from "../lib/Api";
import { LOGIN_PATH, NEW_CREDENTIALS_PATH } from "./paths";
import InformationBox from "../components/InformationBox";
import { addCloseConfirmation, removeCloseConfirmation } from "../lib/confirmation";
import SuccessfulConfirmation from "../components/SuccessfulConfirmation";
import VersionText from "../components/VersionText";
import { resetNewCredentials } from "../store/setupNewCredentials";

class ConfirmNewCredentials extends React.PureComponent {
  constructor(props) {
    super(props);

    this.migrate = this.migrate.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onResendCode = this.onResendCode.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.handleMigration = this.handleMigration.bind(this);
    this.validateStorage = this.validateStorage.bind(this);

    let attempt = 1;

    if (props.match && props.match.params && props.match.params.attempt) {
      attempt = props.match.params.attempt;
    }

    this.state = {
      confirmation: true,
      code: undefined,
      loading: false,
      submitText: "Potwierdź",
      validationError: false,
      codeResendClicked: false,
      errorMessage: undefined,
      redirectResponse: null,
      refreshSite: false,
      attempt,
      redirect: null
    };
  }

  validateStorage() {
    const { history } = this.props;

    if (!Storage.getToken() || !Storage.getEmail() || !Storage.getPassword()) {
      history.push(LOGIN_PATH);

      return null;
    }
  }

  componentDidMount() {
    this.props.setSidebarVisibility(false);

    this.updateCloseConfirmation(this.state.confirmation);
    this.validateStorage();
  }

  componentDidUpdate() {
    this.updateCloseConfirmation(this.state.confirmation);
    this.validateStorage();
  }

  componentWillUnmount() {
    removeCloseConfirmation();
  }

  onChangeEmail(event) {
    event.preventDefault();
    Storage.setEmail(null);
    Storage.setPassword(null);

    this.props.history.push(NEW_CREDENTIALS_PATH);
  }

  onValueChange(event) {
    event.preventDefault();
    const { name, value } = event.target;

    const newState = {
      validationError: false,
      errorMessage: undefined,
      resultStatus: undefined
    };
    newState[name] = value;
    this.setState(newState);
  }

  async handleMigration(login, password, code) {
    if (
      login.length === 0 ||
      code.length === 0 ||
      login.indexOf("@") === -1 ||
      login.indexOf(".") === -1 ||
      password.length === 0
    ) {
      this.setState({ validationError: true, errorMessage: undefined, refreshSite: false });
      return;
    }
    this.setState({
      loading: true,
      validationError: false,
      errorMessage: undefined,
      refreshSite: false
    });

    try {
      const result = await Api.updateUser(login, password, Storage.getToken(), code);
      const { status, json } = result;

      if (status >= 200 && status < 400) {
        Storage.setLogin(login);
        if (status === 200) {
          this.setState({
            confirmation: false,
            redirectResponse: result,
            refreshSite: false
          });
        } else {
          this.props.history.push(LOGIN_PATH);
        }
      } else if (status === 409) {
        this.props.resetNewCredentials(json.errors);

        this.setState({
          redirect: NEW_CREDENTIALS_PATH
        });
      } else {
        this.setState({
          loading: false,
          validationError: true,
          refreshSite: false,
          errorMessage: Array.isArray(json.errors) ? json.errors.join(", ") : undefined
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        validationError: true,
        refreshSite: false
      });
    }
  }

  migrate(event) {
    event.preventDefault();
    const { code } = this.state;

    this.handleMigration(Storage.getEmail(), Storage.getPassword(), code);
  }

  onResendCode(event) {
    event.preventDefault();

    this.handleResendCode(Storage.getEmail(), Storage.getPassword(), Storage.getToken());
  }

  updateCloseConfirmation(confirmExit) {
    if (confirmExit) {
      addCloseConfirmation("");
    } else {
      removeCloseConfirmation();
    }
  }

  async handleResendCode(login, password, token) {
    this.setState({
      code: "",
      loading: true,
      codeResendClicked: true,
      validationError: false,
      refreshSite: false
    });
    try {
      const result = await Api.verifyUser(login, password, token);
      const { json, status } = result;
      if (status >= 400) {
        if (json.attempt >= 3) {
          this.setState({
            loading: false,
            attempt: json.attempt,
            validationError: true,
            refreshSite: true
          });
        } else {
          this.setState({
            loading: false,
            attempt: json.attempt,
            validationError: true,
            errorMessage: Array.isArray(json.errors) ? json.errors.join(", ") : undefined
          });
        }
      } else {
        this.setState({
          loading: false,
          code: "",
          attempt: json.attempt
        });
      }
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  }

  renderSuccessfulConfirmation() {
    const {
      json: { url }
    } = this.state.redirectResponse;

    if (!url) {
      return <Redirect to={LOGIN_PATH} />;
    }

    return <SuccessfulConfirmation target={url} />;
  }

  render() {
    const {
      code,
      submitText,
      loading,
      validationError,
      errorMessage,
      codeResendClicked,
      attempt,
      redirectResponse,
      refreshSite,
      redirect
    } = this.state;

    if (redirectResponse) {
      return this.renderSuccessfulConfirmation();
    }

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    let infoText = null;

    if (codeResendClicked) {
      infoText = `Wysłaliśmy ponownie kod autoryzacyjny na adres: ${Storage.getEmail()}. Jeśli nadal nie dotarł, sprawdź folder SPAM lub zweryfikuj, czy adres jest poprawny.`;
    }

    return (
      <React.Fragment>
        <Header>Potwierdź adres e-mail</Header>
        <InformationBox secondary title="Sprawdź swój e-mail">
          <p className="a-text">
            {"Dbamy o najwyższy poziom bezpieczeństwa. Dlatego na Twój e-mail: "}
            <strong>{Storage.getEmail()}</strong>
            {" wysłaliśmy kod, który pozwoli Ci potwierdzić, że jesteś właścicielem tego konta."}
          </p>
        </InformationBox>
        <ConfirmationCodeForm
          submitText={submitText}
          code={code}
          email={Storage.getEmail()}
          loading={loading}
          error={validationError}
          errorMessage={this.props.errors || errorMessage}
          onSubmit={this.migrate}
          onValueChange={this.onValueChange}
          onChangeEmail={this.onChangeEmail}
          onResendCode={this.onResendCode}
          refreshSite={refreshSite}
          infoText={infoText}
          titleText={attempt > 1 ? `Wpisz kod nr ${attempt} z e-maila` : "Wpisz kod z e-maila"}
        />
        {attempt > 1 ? (
          <VersionText>vn: F6x302</VersionText>
        ) : (
          <VersionText>vn: F6x200</VersionText>
        )}
      </React.Fragment>
    );
  }
}

ConfirmNewCredentials.propTypes = {
  match: PropTypes.object,
  setSidebarVisibility: PropTypes.func,
  resetNewCredentials: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  resetNewCredentials: (errors = []) => {
    dispatch(resetNewCredentials(errors))}
});

const mapStateToProps = (state) => ({
  errors: state.errors && state.errors.length > 1 ? state.errors.join(', ') : null
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmNewCredentials);
