import React from "react";
import PropTypes from "prop-types";
import { Form, FormRow } from "@homepl/onestore-ui-components/dist/Form";
import { ErrorText } from "@homepl/onestore-ui-components/dist/ErrorText";
import {
  LabelBar,
  LabelBarText,
} from "@homepl/onestore-ui-components/dist/LabelBar";
import {
  CheckoutTextWithSub,
  CheckoutTextWithSubTitle,
  CheckoutTextWithSubSubtext,
} from "@homepl/onestore-ui-components/dist/CheckoutTextWithSub";
import FormInput from "../FormInput";
import LoginFormFooter from "../LoginFormFooter";

export default function ConfirmationCodeForm({
  code,
  email,
  error,
  errorMessage,
  onValueChange,
  loading,
  onSubmit,
  submitText,
  onChangeEmail,
  onResendCode,
  infoText,
  titleText,
  titleSubtext,
  codeInputLabel,
  footerMainText,
  footerResendText,
  footerChangeEmail,
  refreshSite,
}) {
  return (
    <Form>
      <form onSubmit={onSubmit}>
        <LabelBar>
          <LabelBarText>
            <CheckoutTextWithSub>
              <CheckoutTextWithSubTitle>{titleText}</CheckoutTextWithSubTitle>
              <CheckoutTextWithSubSubtext>
                {`${titleSubtext}: `}
                <strong>{email}</strong>
              </CheckoutTextWithSubSubtext>
            </CheckoutTextWithSub>
          </LabelBarText>
        </LabelBar>
        <FormRow>
          <FormInput
            value={code}
            name="code"
            onValueChange={onValueChange}
            label={codeInputLabel}
            loading={loading}
            error={error}
            maxLength={4}
          />
        </FormRow>
        <FormRow>
          {error &&
            (refreshSite ? (
              <ErrorText>
                Limit wysyłki został wyczerpany. <a href="/">Kliknij</a> aby
                odświeżyć stronę i zacząć od nowa.
              </ErrorText>
            ) : (
              <ErrorText>{errorMessage}</ErrorText>
            ))}
        </FormRow>
        <FormRow>
          <div className="o-links-with-description">
            <div className="o-links-with-description__desc">
              <p className="a-text a-text--secondary a-text--tiny">
                {footerMainText}
              </p>
            </div>
            <div className="o-links-with-description__links">
              <button
                type="button"
                onClick={onResendCode}
                className="a-link a-link--tiny a-link--secondary"
              >
                {footerResendText}
              </button>
              <button
                type="button"
                onClick={onChangeEmail}
                className="a-link a-link--tiny a-link--secondary"
              >
                {footerChangeEmail}
              </button>
            </div>
          </div>
        </FormRow>
        <LoginFormFooter
          hideRecovery
          onClick={onSubmit}
          loading={loading}
          disabled={code.length === 0}
          submitText={submitText}
          infoText={infoText}
        />
      </form>
    </Form>
  );
}

ConfirmationCodeForm.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResendCode: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  refreshSite: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  code: PropTypes.string,
  email: PropTypes.string,
  submitText: PropTypes.string,
  infoText: PropTypes.string,
  titleText: PropTypes.string,
  titleSubtext: PropTypes.string,
  codeInputLabel: PropTypes.string,
  footerMainText: PropTypes.string,
  footerResendText: PropTypes.string,
  footerChangeEmail: PropTypes.string,
};

ConfirmationCodeForm.defaultProps = {
  error: false,
  errorMessage: "Spróbuj ponownie lub odśwież stronę aby zacząć od nowa.",
  loading: false,
  refreshSite: false,
  email: "",
  code: "",
  submitText: "Potwierdź",
  infoText: "",
  titleText: "Wpisz kod z e-maila",
  titleSubtext: "Wysłaliśmy go na adres",
  codeInputLabel: "Kod z maila",
  footerMainText: "E-mail z kodem nie dotarł?",
  footerResendText: "Wyślij ponownie",
  footerChangeEmail: "Zmień e-mail",
};
