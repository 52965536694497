import React from 'react';
import PropTypes from 'prop-types';

export default function VersionText({ children }) {
  return (
    <div className="m-version-text">{children}</div>
  );
}

VersionText.propTypes = {
  children: PropTypes.node.isRequired,
};
