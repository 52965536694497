import React from 'react';
import PropTypes from 'prop-types';
import { LoginHeader, LoginHeaderLogo, LoginHeaderTitle } from '@homepl/onestore-ui-components/dist/Login';
import { Link as RouterLink } from 'react-router-dom';

export default function PageHeader({ children, headerClassName }) {
  return (
    <LoginHeader>
      <LoginHeaderLogo>
        <RouterLink to="/">
          <img src="/images/logo.svg" alt="Home.pl" />
        </RouterLink>
      </LoginHeaderLogo>
      <LoginHeaderTitle>
        <h2 className={headerClassName}>{children}</h2>
      </LoginHeaderTitle>
    </LoginHeader>
  );
}

PageHeader.propTypes = {
  children: PropTypes.node,
  headerClassName: PropTypes.string,
};

PageHeader.defaultProps = {
  children: null,
  headerClassName: null,
};
