import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormRow } from '@homepl/onestore-ui-components/dist/Form';
import { LabelBar, LabelBarText } from '@homepl/onestore-ui-components/dist/LabelBar';
import { Cta, CtaBtn, CtaLink } from '@homepl/onestore-ui-components/dist/Cta';
import { Link as RouterLink } from 'react-router-dom';
import block from '@homepl/onestore-bem';
import { ErrorText } from '@homepl/onestore-ui-components/dist/ErrorText';
import {
  CheckoutTextWithSub,
  CheckoutTextWithSubTitle,
  CheckoutTextWithSubSubtext,
} from '@homepl/onestore-ui-components/dist/CheckoutTextWithSub';
import FormInput from '../FormInput';

const aBtn = block('a-btn');
export default function PasswordRecoveryForm({
  onSubmit,
  onValueChange,
  error,
  loading,
  loginSubtext,
  submitText,
  value,
  errorMessage,
}) {
  return (
    <React.Fragment>
      <LabelBar>
        <LabelBarText>
          <CheckoutTextWithSub>
            <CheckoutTextWithSubTitle>Login</CheckoutTextWithSubTitle>
            <CheckoutTextWithSubSubtext>{loginSubtext}</CheckoutTextWithSubSubtext>
          </CheckoutTextWithSub>
        </LabelBarText>
      </LabelBar>
      <Form>
        <form onSubmit={onSubmit}>
          <FormRow>
            <FormInput
              name="login"
              label="Login"
              onValueChange={onValueChange}
              error={error}
              loading={loading}
              value={value}
            />
          </FormRow>
          <FormRow>{error && <ErrorText>{errorMessage}</ErrorText>}</FormRow>
          <FormRow>
            <Cta>
              <CtaBtn>
                <button
                  className={aBtn.is({ loading, error, disabled: value.length === 0 }).toString()}
                  disabled={loading}
                  onClick={onSubmit}
                  type="button"
                >
                  {submitText}
                </button>
              </CtaBtn>
            </Cta>
          </FormRow>
          <FormRow>
            <Cta>
              <CtaLink>
                <RouterLink to="/" className="a-link a-link--small">
                  Wróć do logowania
                </RouterLink>
              </CtaLink>
            </Cta>
          </FormRow>
        </form>
      </Form>
    </React.Fragment>
  );
}

PasswordRecoveryForm.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  loginSubtext: PropTypes.string,
  value: PropTypes.string,
  submitText: PropTypes.string,
};

PasswordRecoveryForm.defaultProps = {
  error: false,
  loading: false,
  errorMessage: '',
  loginSubtext: 'Adres e-mail lub identyfikator istniejącej usługi',
  value: '',
  submitText: 'Przejdź dalej',
};
