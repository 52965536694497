export const STORAGE_LOGIN_KEY = 'login';

export default class Storage {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
   */
  static isAvailable() {
    try {
      const x = '__storage_test__';
      window.localStorage.setItem(x, x);
      window.localStorage.removeItem(x);
      return true;
    } catch (e) {
      return e instanceof DOMException && (
      // everything except Firefox
        e.code === 22
        // Firefox
        || e.code === 1014
        // test name field too, because code might not be present
        // everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
        // acknowledge QuotaExceededError only if there's something already stored
        && window.localStorage.length !== 0;
    }
  }

  static get(key) {
    if (!Storage.isAvailable()) {
      return null;
    }
    return window.localStorage.getItem(key);
  }

  static set(key, value) {
    if (!Storage.isAvailable()) {
      return;
    }
    if (undefined === value) {
      this.remove(key);
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  static remove(key) {
    if (!Storage.isAvailable()) {
      return;
    }
    window.localStorage.removeItem(key);
  }

  static getLogin() {
    return Storage.get(STORAGE_LOGIN_KEY) || '';
  }

  static setLogin(value) {
    Storage.set(STORAGE_LOGIN_KEY, value);
  }

  static setToken(value) {
    window.oneLoginToken = value;
  }

  static getToken() {
    return window.oneLoginToken || null;
  }

  static setPassword(value) {
    window.oneLoginPassword = value;
  }

  static getPassword() {
    return window.oneLoginPassword || null;
  }

  static setEmail(value) {
    window.oneLoginEmail = value;
  }

  static getEmail() {
    return window.oneLoginEmail || null;
  }
}
