import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, batch } from "react-redux";
import Header from "../components/PageHeader";
import LoginForm from "../components/LoginForm";
import { Redirect } from "react-router";
import { NEW_CREDENTIALS_CONFIRM_PATH } from "./paths";
import InformationBox from "../components/InformationBox";
import VersionText from "../components/VersionText";
import {
  validateNewCredentials,
  setNewLogin,
  setNewPassword,
  getAttempts,
  getNewCredentials,
  isLoading,
  hasErrors,
  getErrors,
  isSuccess,
  setupNewCredentials,
  resetNewCredentials
} from "../store/setupNewCredentials";

export default function SetupNewCredentials({ setSidebarVisibility }) {
  const attempts = useSelector(getAttempts);
  const { login, password } = useSelector(getNewCredentials);
  const loading = useSelector(isLoading);
  const error = useSelector(hasErrors);
  const errors = useSelector(getErrors);
  const success = useSelector(isSuccess);

  const dispatch = useDispatch();

  const handleMigration = useCallback(
    event => {
      event.preventDefault();
      batch(() => {
        dispatch(validateNewCredentials());

        dispatch(setupNewCredentials(login, password));
      });
    },
    [dispatch]
  );

  const handleValueChange = useCallback(
    event => {
      event.preventDefault();

      const { name, value } = event.target;

      name === "login" && dispatch(setNewLogin(value));
      name === "password" && dispatch(setNewPassword(value));
    },
    [dispatch, setNewLogin, setNewPassword]
  );

  useEffect(() => {
    setSidebarVisibility(false);

    return () => {
      dispatch(resetNewCredentials());
    };
  }, [setSidebarVisibility, dispatch]);

  if (success) {
    const path = `${NEW_CREDENTIALS_CONFIRM_PATH}${attempts ? `/${attempts}` : ""}`;

    return <Redirect to={path} />;
  }

  return (
    <>
      <Header>Nowy panel, nowy login</Header>

      <InformationBox
        secondary
        title="Zyskujesz dostęp do nowego panelu"
        list={[
          "Nowy panel umożliwi Ci szybką i intuicyjną konfigurację usług,",
          "Wygodna obsługa na urządzeniach przenośnych z systemami iOS i Android."
        ]}
      >
        <p className="a-text">
          {"Przenieśliśmy Twoje usługi do nowego lepszego panelu. Żeby zacząć z niego korzystać"}{" "}
          <strong>ustaw nowy login (Twój adres e-mail)</strong>
        </p>
      </InformationBox>

      <LoginForm
        title="Ustaw nowy login i hasło"
        titleSubtext="Adres e-mail będzie nowym loginem"
        loginPlaceholder="Twój aktualny adres e-mail"
        passwordPlaceholder="Nowe hasło"
        hideRecovery
        submitText="Zastosuj"
        login={login}
        password={password}
        onSubmit={handleMigration}
        onValueChange={handleValueChange}
        loading={loading}
        error={error}
        errorMessage={errors.join(", ")}
        validatePassword
      />

      <VersionText>vn: F6x100</VersionText>
    </>
  );
}

SetupNewCredentials.propTypes = {
  setSidebarVisibility: PropTypes.func
};
