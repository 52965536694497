import React from "react";

export default function LabeledInfo({ label, text }) {
  return (
    <div className="o-links-with-description--flex">
      <div className="o-links-with-description--flex__label">{label}</div>

      <p className="o-links-with-description--flex__text">{text}</p>
    </div>
  );
}
