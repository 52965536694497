
/**
 * Funkcja dodająca potwierdzenie podczas kliknięcia odśwież lub zamknij.
 *
 * @function
 * @param {string} message Opcjonalna wiadomość.
 */
export function addCloseConfirmation(message) {
  // eslint-disable-next-line no-restricted-globals
  window.onbeforeunload = () => confirm(message);
}

/**
 * Funkcja usuwająca potwierdzenie odświeżenia/zamknięcia strony.
 *
 * @function
 */
export function removeCloseConfirmation() {
  window.onbeforeunload = null;
}
