import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
import App from './App';

if (window.appConfig.sentryDsn) {
  Raven
    .config(window.appConfig.sentryDsn)
    .install();

  if (window.appConfig.release) {
    Raven.setRelease(window.appConfig.release);
  }
}

Raven.context(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
