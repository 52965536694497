import * as React from 'react';
import PropTypes from 'prop-types';
import block from '@homepl/onestore-bem';
import Form, { FormRow } from '@homepl/onestore-ui-components/dist/Form';
import { Cta, CtaBtn } from '@homepl/onestore-ui-components/dist/Cta';
import SuccessTick from './SuccessTick';
import PageHeader from '../PageHeader';

const aBtn = block('a-btn');

function SuccessfulConfirmation({ target }) {
  return (
    <React.Fragment>
      <PageHeader headerClassName="a-text--success">Gratulacje!</PageHeader>
      <SuccessTick className="u-mb45"/>
      <Form>
        <FormRow>
          <strong className="a-text--big a-text--center">
            Zyskujesz dostęp do nowego panelu
          </strong>
        </FormRow>
        <FormRow className="u-mb45">
          <span className="a-text--center">
            Zmiana loginu i hasła przebiegła pomyślnie
          </span>
        </FormRow>
        <FormRow>
          <Cta>
            <CtaBtn>
              <a
                href={target}
                className={aBtn.toString()}
              >
                Przejdź do panelu
              </a>
            </CtaBtn>
          </Cta>
        </FormRow>
      </Form>
    </React.Fragment>
  );
}

SuccessfulConfirmation.propTypes = {
  target: PropTypes.string.isRequired,
};

export default React.memo(SuccessfulConfirmation);
