import React from 'react';
import PropTypes from 'prop-types';
import {
  LoginChange,
  LoginChangeNew,
  LoginChangeOld,
  LoginChangeArrow,
} from '@homepl/onestore-ui-components/dist/Login';
import {
  SummaryBox,
  SummaryBoxTitle,
  SummaryBoxHeader,
  SummaryBoxSection,
} from '@homepl/onestore-ui-components/dist/SummaryBox';
import TickList from '@homepl/onestore-ui-components/dist/TickList';

export default function InformationBox({
  title,
  text,
  list,
  login,
  oldLogin,
  children,
  secondary,
}) {
  return (
    <SummaryBox>
      { title && (
      <SummaryBoxHeader>
        <SummaryBoxTitle
          secondary={secondary}
          text={title}
        />
      </SummaryBoxHeader>
      )}

      <SummaryBoxSection>
        {text && <p className="a-text">{text}</p>}

        {children}

        {list.length > 0 && (
          <TickList items={list} />
        )}

        { oldLogin && (
          <LoginChange>
            <LoginChangeOld
              text={oldLogin}
            />
            <LoginChangeArrow />
            <LoginChangeNew
              text={login}
            />
          </LoginChange>
        )}
      </SummaryBoxSection>
    </SummaryBox>
  );
}

InformationBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  login: PropTypes.string,
  oldLogin: PropTypes.string,
  children: PropTypes.node,
  secondary: PropTypes.bool,
};

InformationBox.defaultProps = {
  title: '',
  text: '',
  list: [],
  login: null,
  oldLogin: null,
  children: null,
  secondary: false,
};
