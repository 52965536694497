import * as React from 'react';
import * as PropTypes from 'prop-types';
import Validator from '@homepl/onestore-ui-components/dist/Validator';
import ValidatorItem from '@homepl/onestore-ui-components/dist/shapes/ValidatorItem';

const validatorItems = [
  {
    label: 'Mała litera',
    pattern: /[a-z]+/,
  },
  {
    label: 'Wielka litera',
    pattern: /[A-Z]+/,
  },
  {
    label: 'Znak specjalny',
    pattern: /[^a-zA-Z0-9]/,
  },
  {
    label: 'Min. 8 znaków',
    pattern: /([\d\w]|[^\d\w]){8}/,
  },
];

function validateItems(items, value) {
  return items.map(item => ({
    label: item.label,
    status: value
      ? item.pattern.test(value)
        ? 'success'
        : 'error'
      : null,
  }));
}

function PasswordValidator({ items, value }) {
  const [validatedItems, setValidatedItems] = React.useState(validateItems(items, value));

  React.useEffect(() => {
    setValidatedItems(validateItems(items, value));
  }, [value]);

  return <Validator items={validatedItems} />;
}

PasswordValidator.propTypes = {
  items: PropTypes.arrayOf(ValidatorItem),
  value: PropTypes.string,
};

PasswordValidator.defaultProps = {
  items: validatorItems,
  value: null,
};

export default React.memo(PasswordValidator);
