import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormRow } from '@homepl/onestore-ui-components/dist/Form';
import { ErrorText } from '@homepl/onestore-ui-components/dist/ErrorText';
import { LabelBar, LabelBarText } from '@homepl/onestore-ui-components/dist/LabelBar';
import {
  CheckoutTextWithSub,
  CheckoutTextWithSubTitle,
  CheckoutTextWithSubSubtext,
} from '@homepl/onestore-ui-components/dist/CheckoutTextWithSub';
import FormInput from '../FormInput';
import LoginFormFooter from '../LoginFormFooter';

export default function LoginForm({
  login,
  onSavedLoginClear,
  error,
  errorMessage,
  password,
  onValueChange,
  loading,
  onSubmit,
  submitText,
  infoText,
  titleSubtext,
}) {
  return (
    <Form>
      <form onSubmit={onSubmit}>
        <LabelBar>
          <LabelBarText>
            <CheckoutTextWithSub>
              <CheckoutTextWithSubTitle>{login}</CheckoutTextWithSubTitle>
              <CheckoutTextWithSubSubtext>
                {titleSubtext}
              </CheckoutTextWithSubSubtext>
            </CheckoutTextWithSub>
          </LabelBarText>
          <div className="o-label-bar__cta">
            <button type="button" className="a-link a-link--small" onClick={onSavedLoginClear}>Zmień</button>
          </div>
        </LabelBar>
        <FormRow>
          <FormInput
            value={password}
            name="password"
            onValueChange={onValueChange}
            label="Wpisz swoje hasło"
            loading={loading}
            error={error}
            type="password"
          />
        </FormRow>
        <FormRow>
          {error && (
            <ErrorText>{errorMessage}</ErrorText>
          )}
        </FormRow>
        <LoginFormFooter
          onClick={onSubmit}
          loading={loading}
          disabled={login.length === 0 || password.length === 0}
          submitText={submitText}
          infoText={infoText}
        />
      </form>
    </Form>
  );
}

LoginForm.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSavedLoginClear: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  login: PropTypes.string,
  password: PropTypes.string,
  submitText: PropTypes.string,
  infoText: PropTypes.string,
  titleSubtext: PropTypes.string,
};

LoginForm.defaultProps = {
  error: false,
  errorMessage: 'Login i/lub hasło są nieprawidłowe. Pamiętaj, że Twoim loginem jest adres e-mail (nowy panel) lub nazwa usługi (stary panel).',
  loading: false,
  password: '',
  login: '',
  submitText: 'Zaloguj się',
  infoText: '',
  titleSubtext: 'Logowanie do panelu zarządzania',
};
