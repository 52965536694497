import React from 'react';
import PropTypes from 'prop-types';
import { Focus } from '@homepl/onestore-ui-components/dist/containers/Focus';
import { Toggle } from '@homepl/onestore-ui-components/dist/containers/Toggle';
import {
  InputField,
  InputFieldInfo,
  InputFieldInput,
  InputFieldToggle,
} from '@homepl/onestore-ui-components/dist/InputField';
import { FormField } from '@homepl/onestore-ui-components/dist/Form';
import PasswordValidator from '../PasswordValidator';

export default function FormInput({
  onValueChange,
  loading,
  value,
  placeholder,
  label,
  type,
  error,
  name,
  validatePassword,
  maxLength,
}) {
  return (
    <FormField>
      <Focus render={({ focus, onBlur, onFocus }) => (
        <Toggle render={({ toggled, onToggle }) => (
          <React.Fragment>
            <InputField
              password={type === 'password'}
              error={error}
              value={value.length > 0}
              success={value.length > 0 && !error}
              focus={focus}
            >
              <InputFieldInfo>{label}</InputFieldInfo>
              <InputFieldInput
                value={value}
                name={name}
                type={toggled ? 'text' : type}
                disabled={loading}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onValueChange}
                placeholder={placeholder || label}
                maxLength={maxLength}
                data-hj-whitelist // Whitelista inputów na potrzeby Hot Jara --> https://help.hotjar.com/hc/en-us/articles/115015563287-Whitelisting-Input-Fields
              />
              { type === 'password' && (
                <InputFieldToggle
                  toggled={toggled}
                  onToggle={onToggle}
                  offIcon="#checkout--password-show"
                  offText="pokaż"
                  onIcon="#checkout--password-hide"
                  onText="ukryj"
                />
              )}
            </InputField>
            { validatePassword && (
              <PasswordValidator value={value} />
            )}
          </React.Fragment>
        )}
        />
      )}
      />
    </FormField>
  );
}

FormInput.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  validatePassword: PropTypes.bool,
  maxLength: PropTypes.number,
};

FormInput.defaultProps = {
  placeholder: '',
  type: 'text',
  loading: false,
  error: false,
  validatePassword: false,
  maxLength: null,
};
