import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FormRow } from '@homepl/onestore-ui-components/dist/Form';
import { Cta, CtaBtn, CtaLink } from '@homepl/onestore-ui-components/dist/Cta';
import block from '@homepl/onestore-bem';

const aBtn = block('a-btn');

export default function LoginFormFooter({
  onClick, loading, submitText, disabled, error, hideRecovery, infoText,
}) {
  return (
    <React.Fragment>
      {infoText && (
        <FormRow>
          <div className="m-desc-item">
            <img src="/images/notify-icon.svg" alt="informacja" className="m-desc-item__img" />
            <div className="m-desc-item__content">
              <p className="m-desc-item__text">{infoText}</p>
            </div>
          </div>
        </FormRow>
      )}

      <FormRow>
        <Cta>
          <CtaBtn>
            <button
              className={aBtn
                .is({
                  disabled,
                  loading,
                  error
                })
                .toString()}
              disabled={loading}
              onClick={onClick}
              type="submit"
            >
              {submitText}
            </button>
          </CtaBtn>
        </Cta>
      </FormRow>
      {!hideRecovery && (
        <FormRow>
          <Cta>
            <CtaLink>
              <RouterLink to="/reset-hasla" className="a-link a-link--small">
                Nie pamiętasz hasła?
              </RouterLink>
            </CtaLink>
          </Cta>
        </FormRow>
      )}
    </React.Fragment>
  );
}

LoginFormFooter.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hideRecovery: PropTypes.bool,
  submitText: PropTypes.string.isRequired,
  infoText: PropTypes.string,
};

LoginFormFooter.defaultProps = {
  hideRecovery: false,
  disabled: false,
  loading: false,
  error: false,
  infoText: null,
};
