import React from "react";
import SlickSlider from "react-slick";

const slides = [
  <div key="slide" className="o-aside-slider__slide o-aside-slider__slide--white">
    <div
      className="o-aside-slider__slide-wrapper"
      style={{ backgroundImage: 'url("/images/zmiany-logowanie.png")' }}
    >
      <span className="a-text a-text--small a-text--secondary">Czy wiesz, że...</span>
      <p>
        Jeśli dokonałeś zamówienia po 27 maja 2018, Twoim loginem w home.pl jest adres e-mail podany
        podczas zakupów.
      </p>
      <a
        href="https://pomoc.home.pl/baza-wiedzy/jak-zalogowac-sie-w-panelu-home-pl-do-uslugi"
        target="_blank"
        rel="noopener noreferrer"
        className="a-link a-link--small"
      >
        Dowiedz się więcej
      </a>
    </div>
  </div>,

  <div key="slide2" className="o-aside-slider__slide o-aside-slider__slide--white">
    <div
      className="o-aside-slider__slide-wrapper"
      style={{ backgroundImage: 'url("/images/odzyskanie-hasla.png")' }}
    >
      <span className="a-text a-text--small a-text--secondary">Czy wiesz, że...</span>
      <p>
        Jeżeli zapomniałeś hasła lub loginu, prawdopodobnie możesz odszukać je w swojej przeglądarce
        internetowej.
      </p>
      <a
        href="https://pomoc.home.pl/baza-wiedzy/jak-sprawdzic-zapisane-haslo-w-przegladarce?utm_source=panel.home.pl"
        target="_blank"
        rel="noopener noreferrer"
        className="a-link a-link--small"
      >
        Dowiedz się, jak to zrobić
      </a>
    </div>
  </div>,

  <div key="slide3" className="o-aside-slider__slide o-aside-slider__slide--white">
    <div
      className="o-aside-slider__slide-wrapper"
      style={{ backgroundImage: 'url("/images/certyfikaty-multidomenowe.png")' }}
    >
      <span className="a-text a-text--small a-text--secondary">Czy wiesz, że...</span>
      <p>Jednym certyfikatem SSL możesz zabezpieczyć aż do 5 różnych domen.</p>
      <a
        href="https://home.pl/ssl-multidomenowy/?utm_source=onelogin&utm_medium=referral&utm_campaign=ssl_multidomain"
        target="_blank"
        rel="noopener noreferrer"
        className="a-link a-link--small"
      >
        Dowiedz się, jak to zrobić
      </a>
    </div>
  </div>,

  <div key="slide4" className="o-aside-slider__slide o-aside-slider__slide--white">
    <div
      className="o-aside-slider__slide-wrapper"
      style={{ backgroundImage: 'url("/images/2fa.png")' }}
    >
      <span className="a-text a-text--small a-text--secondary">Czy wiesz, że...</span>
      <p>
        Możesz skuteczniej chronić dostęp do swojego konta z darmową aplikacją home.pl? Włącz
        uwierzytelnianie dwuskładnikowe (2FA) i korzystaj z dodatkowej weryfikacji za pomocą
        jednorazowego kodu.
      </p>
      <a
        href="https://home.pl/2fa/?utm_source=onelogin&utm_medium=referral"
        target="_blank"
        rel="noopener noreferrer"
        className="a-link a-link--small"
      >
        Włącz dodatkową ochronę
      </a>
    </div>
  </div>
];

export default function Slider() {
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 8000,
    pauseOnDotsHover: true,
    infinite: true
  };

  if (slides.length > 1) {
    return (
      <SlickSlider className="o-aside-slider" {...settings}>
        {slides}
      </SlickSlider>
    );
  }
  return <div className="o-aside-slider">{slides}</div>;
}
