import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Header from "../components/PageHeader";
import LoginForm from "../components/LoginForm";
import SavedLoginForm from "../components/SavedLoginForm";
import Storage from "../lib/Storage";
import Api from "../lib/Api";
import redirect from "../lib/redirect";
import { NEW_CREDENTIALS_PATH, MIGRATION_IN_PROGRESS_PATH } from "./paths";
import InformationBox from "../components/InformationBox";
import VersionText from "../components/VersionText";

function isMigratedAccountWithValidLegacyCredentials({
  migrationDate,
  oldLogin,
  savedLogin,
}) {
  return migrationDate && oldLogin && savedLogin;
}

export default function Login({ history, setSidebarVisibility }) {
  const [state, setState] = useState({
    savedLogin: Storage.getLogin(),
    login: "",
    oldLogin: "",
    newLogin: "",
    migrationDate: "",
    password: "",
    loading: false,
    validationError: false,
    isNotAdministrative: null,
    administrativeLogin: null,
    legacy: null,
    attempts: 0,
    sidebarVisible: true,
    errorReason: ""
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    setSidebarVisibility(state.sidebarVisible);
  }, [state.sidebarVisible]);

  const onSavedLoginClear = (event) => {
    event.preventDefault();

    setState({
      ...state,
      migrationDate: "",
      oldLogin: "",
      savedLogin: "",
      password: "",
      validationError: false,
      loading: false,
      resultStatus: undefined,
      errorMessage: undefined,
      isNotAdministrative: undefined,
      sidebarVisible: true,
    });

    Storage.setLogin("");
  };

  const onValueChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    const newState = {
      validationError: false,
      errorMessage: undefined,
      resultStatus: undefined,
    };
    newState[name] = value;
    setState({ ...state, ...newState });
  };

  const handleAuthorization = async (login, password) => {
    if (login.length === 0 || password.length === 0) {
      setState({ ...state, validationError: true, errorMessage: undefined });
      return;
    }

    setState({
      ...state,
      loading: true,
      validationError: false,
      resultStatus: undefined,
      errorMessage: undefined,
      errorReason: "",
    });

    try {
      const captcha = await executeRecaptcha("Login");
      const result = await Api.authenticate(login, password, captcha);
      const { status, json } = result;

      if (status === 200) {
        if (json.token) {
          setState({
            ...state,
            loading: false,
            resultStatus: status,
            sidebarVisible: false,
          });
          Storage.setToken(json.token);
          Storage.setEmail(login);
          Storage.setPassword(password);

          history.push(NEW_CREDENTIALS_PATH);
        } else if (
          json.administrative_login
          || json.administrative_login === null
        ) {
          setState({
            ...state,
            isNotAdministrative: true,
            password: "",
            administrativeLogin: json.administrative_login || "",
            login: json.administrative_login || "",
            loading: false,
            resultStatus: status,
            sidebarVisible: false,
          });
        } else if (json.migration_in_progress) {
          history.push(MIGRATION_IN_PROGRESS_PATH);
        } else if (json.old_login) {
          setState({
            ...state,
            isNotAdministrative: false,
            administrativeLogin: null,
            savedLogin: json.new_login,
            login: json.new_login,
            newLogin: json.new_login,
            oldLogin: json.old_login,
            migrationDate: json.migration_date,
            loading: false,
            resultStatus: status,
            password: "",
            sidebarVisible: false,
            errorReason: "",
          });
        } else {
          Storage.setLogin(login);
          redirect(result);
        }
      } else if (status >= 400) {
        setState((prevState) => {
          const updatedState = {
            ...prevState,
            loading: false,
            resultStatus: status,
            validationError: true,
            errorReason: typeof json.reason === "string" ? json.reason : "",
            errorMessage:
              // eslint-disable-next-line no-nested-ternary
              status === 401
              && isMigratedAccountWithValidLegacyCredentials(prevState)
                ? "Podane hasło jest nieprawidłowe"
                : Array.isArray(json.errors)
                  ? json.errors.join(", ")
                  : undefined,
          };

          if (json.migration_date) {
            updatedState.migrationDate = json.migration_date;
          }

          if (json.attempts) {
            updatedState.attempts = json.attempts;
          }

          if (json.old_login) {
            updatedState.oldLogin = json.old_login;
          }

          if (json.new_login) {
            updatedState.oldLogin = login;
            updatedState.newLogin = json.new_login;
            updatedState.login = "";
            updatedState.password = "";
            updatedState.sidebarVisible = false;
          }

          if (json.legacy !== null) {
            updatedState.legacy = json.legacy;
          }

          return updatedState;
        });
      }
    } catch (error) {
      setState({
        ...state,
        loading: false,
        resultStatus: 500,
        validationError: true,
      });
    }
  };

  const authenticateWithSavedLogin = (event) => {
    event.preventDefault();

    handleAuthorization(state.savedLogin, state.password);
  };

  const authenticate = (event) => {
    event.preventDefault();

    handleAuthorization(state.login, state.password);
  };

  const {
    errorMessage,
    loading,
    login,
    password,
    resultStatus,
    savedLogin,
    validationError,
    newLogin,
    migrationDate,
    legacy,
    oldLogin,
    isNotAdministrative,
    administrativeLogin,
    attempts,
    errorReason
  } = state;

  let submitText = !loading ? "Zaloguj się" : "Przetwarzanie...";
  let infoText = null;
  let version = null;

  if (resultStatus >= 400 && errorReason !== "maintenance") {
    infoText = "Pamiętaj, że Twoim loginem jest adres e-mail (nowy panel) lub nazwa usługi (stary panel).";
  }
  if (resultStatus >= 400 ) {
    submitText = "Spróbuj ponownie";
    version = legacy ? "vn: F1x200" : "vn: F1x100";
  }

  if (isNotAdministrative && (!newLogin || !administrativeLogin)) {
    return (
      <React.Fragment>
        <Header>Zaloguj się na dane administracyjne</Header>
        <InformationBox secondary title="Informacja">
          {administrativeLogin && (
            <p className="a-text">
              {
                "Próbujesz się zalogować danymi usługi, która nie jest administracyjna. Użyj loginu "
              }
              <strong>{administrativeLogin}</strong>
              {" i wpisz hasło dla tej usługi, by przejść dalej."}
            </p>
          )}
          {!administrativeLogin && (
            <p className="a-text">
              Próbujesz się zalogować danymi usługi, która nie jest
              administracyjna.
              <br />
              Zapraszamy do kontaktu z naszą infolinią
              {" "}
              <a href="tel:+48504502500">504 502 500</a>
.
            </p>
          )}
        </InformationBox>

        <LoginForm
          title="Login"
          login={login}
          password={password}
          onSubmit={authenticate}
          onValueChange={onValueChange}
          loading={loading}
          error={validationError}
          errorMessage={errorMessage}
          validatePassword={false}
          submitText={submitText}
        />
        {administrativeLogin ? (
          <VersionText>vn: F2x400</VersionText>
        ) : (
          <VersionText>vn: F2x404</VersionText>
        )}
      </React.Fragment>
    );
  }

  if (newLogin && migrationDate && !savedLogin && attempts > 1) {
    return (
      <React.Fragment>
        <Header>Użyj adresu e-mail jako loginu</Header>

        {migrationDate && (
          <InformationBox
            secondary
            title="Informacja"
            login={newLogin}
            oldLogin={oldLogin}
          >
            <p className="a-text">
              Udostępniliśmy Ci nowy panel, a
              <strong> Twoim loginem jest teraz adres e-mail. </strong>
              Masz również nowe hasło nadane przez Ciebie lub Twoich
              współpracowników.
            </p>
          </InformationBox>
        )}

        <LoginForm
          title="Login"
          titleSubtext="Adres e-mail"
          login={login}
          password={password}
          onSubmit={authenticate}
          onValueChange={onValueChange}
          loading={loading}
          error={validationError}
          errorMessage={errorMessage}
          submitText={submitText}
          validatePassword={false}
          infoText="Pamiętaj, że Twoje hasło do panelu bazuje teraz na nowych wymaganiach bezpieczeństwa i zawiera: małą literę, wielką literę, znak specjalny i składa się z min. 8 znaków."
        />
        <VersionText>vn: F3x401</VersionText>
      </React.Fragment>
    );
  }

  if (isMigratedAccountWithValidLegacyCredentials(state)) {
    return (
      <React.Fragment>
        <Header>Użyj adresu e-mail jako loginu</Header>
        <InformationBox
          secondary
          title="Informacja"
          login={savedLogin}
          oldLogin={oldLogin}
        >
          <p className="a-text">
            Udostępniliśmy Ci nowy panel, a
            <strong> Twoim loginem jest teraz adres e-mail. </strong>
            Masz również nowe hasło nadane przez Ciebie lub Twoich
            współpracowników.
          </p>
        </InformationBox>

        <LoginForm
          title="Login"
          titleSubtext="Adres e-mail"
          login={login}
          password={password}
          onSubmit={authenticate}
          onValueChange={onValueChange}
          loading={loading}
          error={validationError}
          errorMessage={errorMessage}
          submitText={submitText}
          validatePassword={false}
          infoText="Pamiętaj, że Twoje hasło do panelu bazuje teraz na nowych wymaganiach bezpieczeństwa i zawiera: małą literę, wielką literę, znak specjalny i składa się z min. 8 znaków."
        />
        <VersionText>vn: F3x400</VersionText>
      </React.Fragment>
    );
  }

  if (savedLogin && savedLogin.length > 0) {
    return (
      <React.Fragment>
        <Header>Miło Cię znowu widzieć!</Header>
        <SavedLoginForm
          login={savedLogin}
          password={password}
          onSavedLoginClear={onSavedLoginClear}
          onSubmit={authenticateWithSavedLogin}
          onValueChange={onValueChange}
          loading={loading}
          error={validationError}
          errorMessage={errorMessage}
          submitText={submitText}
          infoText={infoText}
        />
        {version && <VersionText>{version}</VersionText>}
      </React.Fragment>
    );
  }

  const defaultViewProps = {
    title: "Login",
    login,
    password,
    loading,
    infoText,
    validatePassword: false,
    submitText,
    errorMessage,
    error: validationError,
    titleSubtext: "Adres e-mail lub identyfikator istniejącej usługi",
    onSubmit: authenticate,
    onValueChange,
  };

  if (legacy === false) {
    defaultViewProps.titleSubtext = "Adres e-mail";
    defaultViewProps.infoText = "Pamiętaj, że Twoje hasło do panelu bazuje teraz na nowych wymaganiach bezpieczeństwa i zawiera: małą literę, wielką literę, znak specjalny i składa się z min. 8 znaków.";
    version = "vn: F1x105";
  }

  return (
    <React.Fragment>
      <Header>Logowanie do panelu</Header>
      <LoginForm {...defaultViewProps} />
      {version && <VersionText>{version}</VersionText>}
    </React.Fragment>
  );
}

Login.propTypes = {
  history: PropTypes.object,
  setSidebarVisibility: PropTypes.func,
};

Login.defaultProps = {
  history: null,
  setSidebarVisibility: null,
};
